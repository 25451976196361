import React, { useState } from "react";
import classes from "./Header.module.scss";
import { ReactComponent as CleanEnergyLogo } from "../../../assets/icons/clean_energy_mech_logo.svg";
import Button from "../../../Components/Button/Button";
import Usa from "../../../assets/images/usa.png";
import Solutions from "./Solutions/Solutions";
import Platform from "./Platform/Platform";
import FilledArrow from "../../../assets/icons/FilledArrow";
import useIsMobile from "../../../Hooks/UseIsMobile";
import { ReactComponent as BreadCrumb } from "../../../assets/icons/bread_crumb.svg";
import { ReactComponent as SeLogoText } from "../../../assets/icons/se_logo_with_text.svg";
import { ReactComponent as CrossIcon } from "../../../assets/icons/cross_svg.svg";
import { useNavigate } from "react-router-dom";

interface HeaderProps {}

let componentMapper = {
  Solutions: <Solutions />,
  Platform: <Platform />,
  Company: (
    <div className={classes["company-dropdown-container"]}>
      <p>About us</p>
      <p>Careers</p>
      <p>Contact us</p>
    </div>
  ),
  Resources: (
    <div className={classes["resources-dropdown-container"]}>
      <p>Event</p>
      <p>Blogs</p>
    </div>
  ),
};

// const headerKeysArr = ["Solutions", "Company", "Resources"];

// const headerKeysArr = ["About SE", "Solutions", "Events", "Media"];

const headerKeysArr = [
  { text: "About SE", link: "/about-us" },
  { text: "Solutions", link: "/solutions" },
  { text: "Events", link: "/resources/events-overview" },
];

const Header = ({}: HeaderProps) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile(950);

  const [headerKeyVisibility, setHeaderKeyVisibility] = useState({
    Solutions: false,
    Platform: false,
    Company: false,
    Resources: false,
    language: false,
  });
  const [headerPopUpVisibility, setHeaderPopupVisibility] = useState(false);

  // const headerKeyClickHandler = (receivedKey) => () => {
  //   let tempHeaderKeyVisibility = { ...headerKeyVisibility };

  //   for (const key in tempHeaderKeyVisibility) {
  //     if (tempHeaderKeyVisibility.hasOwnProperty(key) && key !== receivedKey) {
  //       tempHeaderKeyVisibility[key] = false;
  //     }
  //   } // hasOwnProperty ensures that only the object's own properties (and not inherited ones) are considered

  //   tempHeaderKeyVisibility[receivedKey] =
  //     !tempHeaderKeyVisibility[receivedKey];

  //   setHeaderKeyVisibility(tempHeaderKeyVisibility);
  // };

  const breadCrumbClickHandler = () => {
    setHeaderPopupVisibility((prevState) => !prevState);
  };

  const crossClickHandler = () => {
    setHeaderPopupVisibility(false);
  };

  const seClickHandler = () => {
    window.open(process.env.REACT_APP_SE_URL, "_blank");
  };

  const aboutClickHandler = () => {
    window.open("https://wtk94jvv-3001.inc1.devtunnels.ms/about-us", "_blank");
  };

  const seRedirection = (endpoint: string) => {
    if (endpoint) {
      window.open(`${process.env.REACT_APP_SE_URL}${endpoint}`, "_blank");
    } else {
      window.open(process.env.REACT_APP_SE_URL, "_blank");
    }
  };

  return (
    <div className={classes["header-container"]}>
      {isMobile ? (
        <>
          <div
            className={classes["bread-crumb-container"]}
            onClick={breadCrumbClickHandler}
          >
            <BreadCrumb />
          </div>
          <div
            className={classes["logo-container"]}
            onClick={() => {
              navigate("/");
            }}
          >
            <CleanEnergyLogo />
          </div>
          <Button
            className={`${classes.btn} ${classes.seBtn}`}
            text="SE"
            btnClickHandler={seClickHandler}
          />
          {headerPopUpVisibility && (
            <div
              className={
                headerPopUpVisibility
                  ? `${classes["header-popup-container"]} ${classes["visible"]}`
                  : `${classes["header-popup-container"]} ${classes["hide"]}`
              }
            >
              <div className={classes["logo-exit-container"]}>
                <div className={classes["se-logo-container"]}>
                  <SeLogoText />
                </div>
                <div
                  className={classes["cross-logo-container"]}
                  onClick={crossClickHandler}
                >
                  <CrossIcon />
                </div>
              </div>
              <div className={classes["hp-links-container"]}>
                <div
                  className={classes["hp-link-element"]}
                  onClick={() => seRedirection("/about-us")}
                >
                  About SE
                </div>
                <div className={classes["separator"]}></div>
                <div
                  className={classes["hp-link-element"]}
                  onClick={() => seRedirection("/solutions")}
                >
                  Solutions
                </div>
                <div className={classes["separator"]}></div>
                <div
                  className={classes["hp-link-element"]}
                  onClick={() => seRedirection("/resources/events-overview")}
                >
                  Events
                </div>
                <div className={classes["separator"]}></div>
                <div
                  className={classes["hp-link-element"]}
                  onClick={() =>
                    window.open(
                      "https://sustainabilityeconomicsnews.com/",
                      "_blank"
                    )
                  }
                >
                  Media
                </div>
                <div className={classes["separator"]}></div>
                <div className={classes["git-btn-container"]}>
                  <Button
                    text="SE"
                    btnClickHandler={() =>
                      window.open(process.env.REACT_APP_SE_URL, "_blank")
                    }
                    btnStyles={{
                      backgroundColor: "#288f90",
                      fontSize: "10.11px",
                      fontWeight: 500,
                      width: "65px",
                      height: "26.72px",
                      borderRadius: "4.33px",
                    }}
                  ></Button>
                </div>
              </div>
              <div className={classes["get-in-touch-container"]}>
                <Button
                  text="Get in touch"
                  btnClickHandler={() =>
                    window.open(
                      `${process.env.REACT_APP_SE_URL}/contact-us`,
                      "_blank"
                    )
                  }
                  btnStyles={{
                    backgroundColor: "#288f90",
                    fontSize: "14px",
                    fontWeight: 400,
                    width: "53vw",
                  }}
                ></Button>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <div
            className={classes["logo-container"]}
            onClick={() => {
              navigate("/");
            }}
          >
            <CleanEnergyLogo />
          </div>
          <div className={classes["header-options-container"]}>
            <div className={classes["dropdown-container"]}>
              {headerKeysArr.map((headerEl, index) => {
                return (
                  <div
                    key={index}
                    className={classes["dropdown-header"]}
                    onClick={() => seRedirection(headerEl.link)}
                  >
                    <p className={classes["dropdown-header-text"]}>
                      {headerEl.text}
                    </p>
                  </div>
                );
              })}

              <div
                className={classes["dropdown-header"]}
                onClick={() =>
                  window.open(
                    "https://sustainabilityeconomicsnews.com/",
                    "_blank"
                  )
                }
              >
                <p className={classes["dropdown-header-text"]}>Media</p>
              </div>
            </div>
          </div>
          <div className={classes["header-end-container"]}>
            <div className={classes["btn-container"]}>
              <Button
                className={`${classes.btn} ${classes.seBtn}`}
                text="SE"
                btnClickHandler={seClickHandler}
              />
              <Button
                className={classes.btn}
                text="Get in touch"
                btnClickHandler={() =>
                  window.open(
                    `${process.env.REACT_APP_SE_URL}/contact-us`,
                    "_blank"
                  )
                }
              />
            </div>

            {/* <div className={classes["language-dropdown-container"]}>
              <div className={classes["language-svg-container"]}>
                <img src={Usa}></img>
              </div>
              <div className={classes["language-text"]}>Eng</div>
              <div className={classes["language-down-arrow-container"]}>
                <FilledArrow />
              </div>
            </div> */}
          </div>
        </>
      )}
    </div>
  );
};

export default Header;
