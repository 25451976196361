import React, { ReactNode } from "react";
import classes from "./Button.module.scss";

interface ButtonProps {
  text: string | ReactNode;
  className?: string;
  btnStyles?: React.CSSProperties;
  btnClickHandler: (e: any) => void;
}

const Button = ({
  text,
  className,
  btnStyles = {},
  btnClickHandler,
}: ButtonProps) => {
  return (
    <div
      className={`${classes["button-container"]} ${className}`}
      style={btnStyles}
      onClick={btnClickHandler}
    >
      {text}
    </div>
  );
};

export default Button;
