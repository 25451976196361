import React, { ReactNode, useState } from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";

interface LayouProps {
  children: ReactNode;
}

const Layout = ({ children }: LayouProps) => {
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
