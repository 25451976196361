import React, { useState, useEffect } from "react";
import classes from "./GlobalPresence.module.scss";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";
import { geoWinkel3 } from "d3-geo-projection";
import { ReactComponent as Usa } from "../../../assets/icons/us.svg";
import { ReactComponent as Uk } from "../../../assets/icons/uk.svg";
import { ReactComponent as Singapore } from "../../../assets/icons/singapore.svg";
import { ReactComponent as India } from "../../../assets/icons/india.svg";
import { ReactComponent as Japan } from "../../../assets/icons/japan.svg";
import { ReactComponent as WorldMap } from "../../../assets/icons/world_map.svg";
import useIsMobile from "../../../Hooks/UseIsMobile";

const GlobalPresence = () => {
  const isMobile = useIsMobile(1100);
  const [mapColor, setMapColor] = useState("#414143");
  const [hovered, setHovered] = useState(false);
  const [customScale, setCustomScale] = useState(78);

  useEffect(() => {
    if (1100 < window.innerWidth && window.innerWidth < 1200) {
      setCustomScale(89);
    } else if (1200 < window.innerWidth && window.innerWidth < 1300) {
      setCustomScale(100);
    } else {
      setCustomScale(78);
    }

    const handleResize = () => {
      if (1100 < window.innerWidth && window.innerWidth < 1200) {
        setCustomScale(110);
      } else if (1200 < window.innerWidth && window.innerWidth < 1300) {
        setCustomScale(100);
      } else {
        setCustomScale(78);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const height = 600;
  // const projection = geoMercator().scale(70); // Adjust scale as needed
  // .translate([50, 450]); // Adjust translation to fit the container

  const projection = geoWinkel3()
    .translate([350, height / 3])
    .scale(130);

  const markers = [
    { coordinates: [-73.935242, 40.73061], name: "US", svg: <Usa /> },
    {
      coordinates: [-1.1696499, 52.6864142],
      name: "UK",
      svg: <Uk />,
    },
    {
      coordinates: [103.85402722430416, 1.2955217421365153],
      name: "Singapore",
      svg: <Singapore />,
    },
    { coordinates: [77.836188, 26.568804], name: "India", svg: <India /> },
    { coordinates: [139.691711, 35.689487], name: "Japan", svg: <Japan /> },
  ];

  const mouseEnterHandler = () => {
    setHovered(true);
    setMapColor("#A9A9AA");
  };

  const mouseLeaveHandler = () => {
    setHovered(false);
    setMapColor("#414143");
  };

  const CustomMarker = ({ coordinates, name }: any) => (
    <>
      <Marker
        coordinates={coordinates}
        onMouseEnter={mouseEnterHandler}
        onMouseLeave={mouseLeaveHandler}
        data-tip={name}
        data-for={`tooltip-${name}`}
      >
        {/* Outer circle to simulate the stroke */}
        <circle
          r={hovered ? 8 : 6}
          fill="transparent"
          stroke={hovered ? "#1868DB" : "#1868DB99"}
          strokeWidth={hovered ? 8 : 6}
        />
        {/* Inner circle */}
        <circle r={hovered ? 4 : 3} fill="#ffff" />

        {hovered && (
          <g>
            {name === "Singapore" ? (
              <rect
                width="50"
                height="20"
                x="-20"
                y="-34"
                rx="2"
                ry="2"
                fill="#111111"
              ></rect>
            ) : name === "India" ? (
              <rect
                width="50"
                height="20"
                x="-26"
                y="-35"
                rx="2"
                ry="2"
                fill="#111111"
              ></rect>
            ) : (
              <rect
                width="50"
                height="20"
                x="-25"
                y="-35"
                rx="2"
                ry="2"
                fill="#111111"
              ></rect>
            )}
            {(name === "US" || name === "UK") && (
              <text
                x="-7"
                y="-22"
                font-family="Neue-Hass"
                font-size="8"
                fill="white"
                letterSpacing={0.5}
                fontWeight={500}
              >
                {name}
              </text>
            )}
            {name !== "US" &&
              name !== "UK" &&
              name !== "Singapore" &&
              name !== "India" && (
                <text
                  x="-10"
                  y="-22"
                  font-family="Neue-Hass"
                  font-size="8"
                  fill="white"
                  letterSpacing={0.5}
                  fontWeight={500}
                >
                  {name}
                </text>
              )}
            {name === "India" && (
              <text
                x="-11"
                y="-22"
                font-family="Neue-Hass"
                font-size="8"
                fill="white"
                letterSpacing={0.5}
                fontWeight={500}
              >
                {name}
              </text>
            )}
            {name === "Singapore" && (
              <text
                x="-15"
                y="-21"
                font-family="Neue-Hass"
                font-size="8"
                fill="white"
                letterSpacing={0.5}
                fontWeight={500}
              >
                {name}
              </text>
            )}
          </g>
        )}
      </Marker>
    </>
  );

  return (
    <div className={classes["global-presence-container"]}>
      <div className={classes["curved-white-container"]}>
        {isMobile ? (
          <div className={classes["map-svg-container"]}>
            <WorldMap />
          </div>
        ) : (
          <div className={classes["map-container"]}>
            <ComposableMap
              projectionConfig={{
                scale: customScale,
                center: [10, -50],
              }}
              projection="geoMercator"
            >
              <Geographies geography="https://unpkg.com/world-atlas@1/world/110m.json">
                {({ geographies }: any) =>
                  geographies.map((geo: any) => {
                    return (
                      <Geography
                        key={geo.rsmKey}
                        geography={geo}
                        style={{
                          default: {
                            fill: mapColor,
                            outline: "none",
                          },
                          hover: {
                            fill: mapColor, // Color on hover
                            outline: "none",
                          },
                        }}
                      />
                    );
                  })
                }
              </Geographies>
              {markers.map((el, i) => {
                return (
                  <CustomMarker
                    key={i}
                    coordinates={el.coordinates}
                    name={el.name}
                  />
                );
              })}
            </ComposableMap>
          </div>
        )}

        <div className={classes["gp-content-container"]}>
          <div className={classes["gp-content-heading"]}>
            <div className={classes["gp-text-our"]}>Our</div>
            <div className={classes["gp-text-global-presence"]}>
              Global Presence
            </div>
            <div className={classes["gp-country-container"]}>
              {markers.map((el) => (
                <div key={el.name} className={classes["gp-country-circle"]}>
                  {el.svg}
                </div>
              ))}
            </div>
          </div>
          <div className={classes["gp-partitioner-container"]}>
            <div className={classes["gp-partitioner"]}></div>
          </div>
          <div className={classes["gp-content-para"]}>
            <p>
              At our core, we prioritize customer intimacy and proximity,
              enabling us to deeply understand your unique needs and challenges.
              Our global footprint, with offices strategically located in the
              US, UK, Singapore, and Japan, combined with our state-of-the-art
              Innovation Capability Centre in India, empowers us to deliver
              tailored solutions.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalPresence;
