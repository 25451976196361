import React from "react";

const Savings = ({ color = "#1868DB" }) => {
  return (
    <svg
      width="35"
      height="33"
      viewBox="0 0 39 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27 17.625C27.5312 17.625 27.9766 17.4453 28.3359 17.0859C28.6953 16.7266 28.875 16.2812 28.875 15.75C28.875 15.2188 28.6953 14.7734 28.3359 14.4141C27.9766 14.0547 27.5312 13.875 27 13.875C26.4688 13.875 26.0234 14.0547 25.6641 14.4141C25.3047 14.7734 25.125 15.2188 25.125 15.75C25.125 16.2812 25.3047 16.7266 25.6641 17.0859C26.0234 17.4453 26.4688 17.625 27 17.625ZM12 13.875H21.375V10.125H12V13.875ZM5.4375 36.375C4.375 32.8125 3.32812 29.2578 2.29688 25.7109C1.26562 22.1641 0.75 18.5312 0.75 14.8125C0.75 11.9375 1.75 9.5 3.75 7.5C5.75 5.5 8.1875 4.5 11.0625 4.5H20.4375C21.3438 3.3125 22.4453 2.39062 23.7422 1.73438C25.0391 1.07813 26.4375 0.75 27.9375 0.75C28.7188 0.75 29.3828 1.02344 29.9297 1.57031C30.4766 2.11719 30.75 2.78125 30.75 3.5625C30.75 3.71875 30.6719 4.07812 30.5156 4.64062C30.3906 4.98438 30.2734 5.33594 30.1641 5.69531C30.0547 6.05469 29.9688 6.42188 29.9062 6.79688L34.1719 11.0625H38.25V24.1406L32.9531 25.875L29.8125 36.375H19.5V32.625H15.75V36.375H5.4375Z"
        fill={color}
      />
    </svg>
  );
};

export default Savings;
