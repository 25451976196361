import React, { useState } from "react";
import classes from "./OurStrategy.module.scss";
import clemStrategy from "../../../assets/images/clemStrategy.svg";
import useIsMobile from "../../../Hooks/UseIsMobile";
import styles from "../../../DesignSystem/_classes.module.scss";

const strategyAndSolutionsContent = [
  "Transition Finance",
  "Low Carbon Business Developer",
  "Blockchain driven marketplace",
  "Transition Credits",
];
const whoWeHelpContent = ["Advisors", "Buyers", "Financiers", "Plant Owners"];

const OurStrategy = () => {
  const ismobile = useIsMobile(850);
  const [stateFlag, setStateFlag] = useState(true);

  const boxClickHandler = (receivedFlag: any) => () => {
    setStateFlag(receivedFlag);
  };

  return (
    <div className={classes["our-strategy-container-parent"]}>
      <div className={classes["curved-white-container"]}>
        <div className={classes["strategy-container"]}>
          <div className={classes["os-title"]}>
            Our <span>Strategy</span> and <span>Solution</span>
          </div>

          <div className={classes["separator"]}></div>

          <div className={classes["strategy-img-container"]}>
            <img
              className={classes["strategy-img"]}
              src={clemStrategy}
              alt="CLEM strategy and solution"
            />
          </div>

          <div
            className={`${classes["cards-container"]} ${styles["hide-scrollbar"]}`}
          >
            {strategyAndSolutionsContent.map((item, index) => (
              <div className={classes.card} key={index}>
                {item}
              </div>
            ))}
          </div>

          <div className={classes["who-we-help-container"]}>
            <div className={classes["title"]}>Who we help</div>
            <div className={classes["separator"]}></div>
            <div
              className={`${classes["who-we-help-cards"]} ${styles["hide-scrollbar"]}`}
            >
              {whoWeHelpContent.map((item, index) => (
                <div className={classes.card} key={index}>
                  {item}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurStrategy;
