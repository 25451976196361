import React from "react";
import classes from "./LandingPage.module.scss";
import HomePage from "./Homepage/HomePage";
import GlobalPresence from "./GlobalPresence/GlobalPresence";
import PracticalAndCraftedApproach from "./PracticalAndCraftedApproach/PracticalAndCraftedApproach";
import GetInTouch from "./GetIntouch/GetInTouch";
import OurStrategy from "./OurStrategy/OurStrategy";

const LandingPage = () => {
  return (
    <div className={classes["landing-page-container"]}>
      <HomePage />
      <GlobalPresence />
      <PracticalAndCraftedApproach />
      <OurStrategy />
      <GetInTouch />
    </div>
  );
};

export default LandingPage;
