import React from "react";
import LandingPage from "../LandingPage/LandingPage";
import AboutUs from "../AboutUs/AboutUs";
import ContactUs from "../ContactUs/ContactUs";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";

type typeRoutesConfig = {
  path?: string | undefined;
  component: React.ElementType;
};

export const routes: typeRoutesConfig[] = [
  {
    path: "/",
    component: LandingPage,
  },
  {
    path: "/Aboutus",
    component: AboutUs,
  },
  {
    path: "/terms",
    component: TermsAndConditions,
  },
];
