import React from "react";

const SeLogoWhite = ({ width = 132, height = 49 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 132 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.3215 43.0803C27.4101 43.0803 29.1033 41.3871 29.1033 39.2985C29.1033 37.2098 27.4101 35.5166 25.3215 35.5166C23.2328 35.5166 21.5396 37.2098 21.5396 39.2985C21.5396 41.3871 23.2328 43.0803 25.3215 43.0803Z"
        fill="white"
      />
      <path
        d="M16.7715 41.93C18.7693 41.93 20.3888 40.3104 20.3888 38.3126C20.3888 36.3148 18.7693 34.6953 16.7715 34.6953C14.7737 34.6953 13.1542 36.3148 13.1542 38.3126C13.1542 40.3104 14.7737 41.93 16.7715 41.93Z"
        fill="white"
      />
      <path
        d="M9.3714 37.6547C11.2783 37.6547 12.8242 36.1088 12.8242 34.2018C12.8242 32.2949 11.2783 30.749 9.3714 30.749C7.46446 30.749 5.91858 32.2949 5.91858 34.2018C5.91858 36.1088 7.46446 37.6547 9.3714 37.6547Z"
        fill="white"
      />
      <path
        d="M33.8717 41.7649C35.9604 41.7649 37.6536 40.0717 37.6536 37.983C37.6536 35.8944 35.9604 34.2012 33.8717 34.2012C31.7831 34.2012 30.0899 35.8944 30.0899 37.983C30.0899 40.0717 31.7831 41.7649 33.8717 41.7649Z"
        fill="white"
      />
      <path
        d="M41.7647 37.6536C43.7625 37.6536 45.382 36.0341 45.382 34.0363C45.382 32.0385 43.7625 30.4189 41.7647 30.4189C39.7669 30.4189 38.1473 32.0385 38.1473 34.0363C38.1473 36.0341 39.7669 37.6536 41.7647 37.6536Z"
        fill="white"
      />
      <path
        d="M49.1641 33.2151C51.1619 33.2151 52.7814 31.5956 52.7814 29.5978C52.7814 27.6 51.1619 25.9805 49.1641 25.9805C47.1663 25.9805 45.5468 27.6 45.5468 29.5978C45.5468 31.5956 47.1663 33.2151 49.1641 33.2151Z"
        fill="white"
      />
      <path
        d="M56.3987 28.4465C58.4873 28.4465 60.1805 26.7533 60.1805 24.6647C60.1805 22.576 58.4873 20.8828 56.3987 20.8828C54.31 20.8828 52.6168 22.576 52.6168 24.6647C52.6168 26.7533 54.31 28.4465 56.3987 28.4465Z"
        fill="white"
      />
      <path
        d="M78.4329 15.1263C80.4307 15.1263 82.0502 13.5067 82.0502 11.5089C82.0502 9.51114 80.4307 7.8916 78.4329 7.8916C76.4351 7.8916 74.8156 9.51114 74.8156 11.5089C74.8156 13.5067 76.4351 15.1263 78.4329 15.1263Z"
        fill="white"
      />
      <path
        d="M63.6334 23.678C65.6312 23.678 67.2508 22.0585 67.2508 20.0607C67.2508 18.0629 65.6312 16.4434 63.6334 16.4434C61.6357 16.4434 60.0161 18.0629 60.0161 20.0607C60.0161 22.0585 61.6357 23.678 63.6334 23.678Z"
        fill="white"
      />
      <path
        d="M71.1972 19.0745C73.195 19.0745 74.8145 17.455 74.8145 15.4572C74.8145 13.4594 73.195 11.8398 71.1972 11.8398C69.1994 11.8398 67.5798 13.4594 67.5798 15.4572C67.5798 17.455 69.1994 19.0745 71.1972 19.0745Z"
        fill="white"
      />
      <path
        d="M86.3244 11.3461C88.2314 11.3461 89.7772 9.8002 89.7772 7.89325C89.7772 5.98631 88.2314 4.44043 86.3244 4.44043C84.4175 4.44043 82.8716 5.98631 82.8716 7.89325C82.8716 9.8002 84.4175 11.3461 86.3244 11.3461Z"
        fill="white"
      />
      <path
        d="M94.7105 8.87928C96.6174 8.87928 98.1633 7.3334 98.1633 5.42646C98.1633 3.51952 96.6174 1.97363 94.7105 1.97363C92.8035 1.97363 91.2576 3.51952 91.2576 5.42646C91.2576 7.3334 92.8035 8.87928 94.7105 8.87928Z"
        fill="white"
      />
      <path
        d="M103.426 7.89303C105.242 7.89303 106.714 6.4208 106.714 4.60472C106.714 2.78863 105.242 1.31641 103.426 1.31641C101.61 1.31641 100.138 2.78863 100.138 4.60472C100.138 6.4208 101.61 7.89303 103.426 7.89303Z"
        fill="white"
      />
      <path
        d="M111.976 8.87952C113.61 8.87952 114.935 7.5546 114.935 5.92023C114.935 4.28586 113.61 2.96094 111.976 2.96094C110.341 2.96094 109.016 4.28586 109.016 5.92023C109.016 7.5546 110.341 8.87952 111.976 8.87952Z"
        fill="white"
      />
      <path
        d="M125.294 18.7458C126.838 18.7458 128.09 17.4941 128.09 15.95C128.09 14.406 126.838 13.1543 125.294 13.1543C123.75 13.1543 122.499 14.406 122.499 15.95C122.499 17.4941 123.75 18.7458 125.294 18.7458Z"
        fill="white"
      />
      <path
        d="M119.704 12.4975C121.248 12.4975 122.5 11.2458 122.5 9.70174C122.5 8.1577 121.248 6.90601 119.704 6.90601C118.16 6.90601 116.908 8.1577 116.908 9.70174C116.908 11.2458 118.16 12.4975 119.704 12.4975Z"
        fill="white"
      />
      <path
        d="M128.912 26.9677C130.456 26.9677 131.708 25.716 131.708 24.172C131.708 22.6279 130.456 21.3762 128.912 21.3762C127.368 21.3762 126.116 22.6279 126.116 24.172C126.116 25.716 127.368 26.9677 128.912 26.9677Z"
        fill="white"
      />
      <path
        d="M127.267 35.3523C128.721 35.3523 129.899 34.1743 129.899 32.7211C129.899 31.2679 128.721 30.0898 127.267 30.0898C125.814 30.0898 124.636 31.2679 124.636 32.7211C124.636 34.1743 125.814 35.3523 127.267 35.3523Z"
        fill="white"
      />
      <path
        d="M122.828 42.2586C124.19 42.2586 125.294 41.1542 125.294 39.7919C125.294 38.4296 124.19 37.3252 122.828 37.3252C121.465 37.3252 120.361 38.4296 120.361 39.7919C120.361 41.1542 121.465 42.2586 122.828 42.2586Z"
        fill="white"
      />
      <path
        d="M115.429 47.5214C116.7 47.5214 117.731 46.4907 117.731 45.2192C117.731 43.9477 116.7 42.917 115.429 42.917C114.157 42.917 113.126 43.9477 113.126 45.2192C113.126 46.4907 114.157 47.5214 115.429 47.5214Z"
        fill="white"
      />
      <path
        d="M106.056 49.0001C107.146 49.0001 108.029 48.1166 108.029 47.0269C108.029 45.9371 107.146 45.0537 106.056 45.0537C104.967 45.0537 104.083 45.9371 104.083 47.0269C104.083 48.1166 104.967 49.0001 106.056 49.0001Z"
        fill="white"
      />
      <path
        d="M97.8346 48.671C98.9244 48.671 99.8078 47.7875 99.8078 46.6978C99.8078 45.608 98.9244 44.7246 97.8346 44.7246C96.7449 44.7246 95.8615 45.608 95.8615 46.6978C95.8615 47.7875 96.7449 48.671 97.8346 48.671Z"
        fill="white"
      />
      <path
        d="M89.6136 46.6973C90.7033 46.6973 91.5867 45.8139 91.5867 44.7242C91.5867 43.6344 90.7033 42.751 89.6136 42.751C88.5238 42.751 87.6404 43.6344 87.6404 44.7242C87.6404 45.8139 88.5238 46.6973 89.6136 46.6973Z"
        fill="white"
      />
      <path
        d="M81.8854 44.0666C82.8843 44.0666 83.6941 43.2568 83.6941 42.2579C83.6941 41.259 82.8843 40.4492 81.8854 40.4492C80.8865 40.4492 80.0767 41.259 80.0767 42.2579C80.0767 43.2568 80.8865 44.0666 81.8854 44.0666Z"
        fill="white"
      />
      <path
        d="M73.9926 39.7913C74.8098 39.7913 75.4722 39.1289 75.4722 38.3117C75.4722 37.4945 74.8098 36.832 73.9926 36.832C73.1754 36.832 72.5129 37.4945 72.5129 38.3117C72.5129 39.1289 73.1754 39.7913 73.9926 39.7913Z"
        fill="white"
      />
      <path
        d="M66.5937 36.1742C67.32 36.1742 67.9088 35.5854 67.9088 34.8591C67.9088 34.1328 67.32 33.5439 66.5937 33.5439C65.8674 33.5439 65.2786 34.1328 65.2786 34.8591C65.2786 35.5854 65.8674 36.1742 66.5937 36.1742Z"
        fill="white"
      />
      <path
        d="M60.3453 32.556C61.0716 32.556 61.6604 31.9672 61.6604 31.2409C61.6604 30.5146 61.0716 29.9258 60.3453 29.9258C59.619 29.9258 59.0302 30.5146 59.0302 31.2409C59.0302 31.9672 59.619 32.556 60.3453 32.556Z"
        fill="white"
      />
      <path
        d="M4.43931 30.2544C6.2554 30.2544 7.72763 28.7821 7.72763 26.966C7.72763 25.15 6.2554 23.6777 4.43931 23.6777C2.62323 23.6777 1.151 25.15 1.151 26.966C1.151 28.7821 2.62323 30.2544 4.43931 30.2544Z"
        fill="white"
      />
      <path
        d="M3.1238 21.8687C4.84903 21.8687 6.2476 20.4701 6.2476 18.7449C6.2476 17.0197 4.84903 15.6211 3.1238 15.6211C1.39857 15.6211 0 17.0197 0 18.7449C0 20.4701 1.39857 21.8687 3.1238 21.8687Z"
        fill="white"
      />
      <path
        d="M5.91858 13.318C7.55295 13.318 8.87787 11.9931 8.87787 10.3587C8.87787 8.72433 7.55295 7.39941 5.91858 7.39941C4.28421 7.39941 2.95929 8.72433 2.95929 10.3587C2.95929 11.9931 4.28421 13.318 5.91858 13.318Z"
        fill="white"
      />
      <path
        d="M20.5536 5.26245C22.0068 5.26245 23.1849 4.08441 23.1849 2.63122C23.1849 1.17804 22.0068 0 20.5536 0C19.1005 0 17.9224 1.17804 17.9224 2.63122C17.9224 4.08441 19.1005 5.26245 20.5536 5.26245Z"
        fill="white"
      />
      <path
        d="M12.3315 7.72917C13.8755 7.72917 15.1272 6.47747 15.1272 4.93343C15.1272 3.38939 13.8755 2.1377 12.3315 2.1377C10.7875 2.1377 9.53577 3.38939 9.53577 4.93343C9.53577 6.47747 10.7875 7.72917 12.3315 7.72917Z"
        fill="white"
      />
      <path
        d="M29.2675 6.57698C30.6298 6.57698 31.7342 5.4726 31.7342 4.11027C31.7342 2.74794 30.6298 1.64355 29.2675 1.64355C27.9052 1.64355 26.8008 2.74794 26.8008 4.11027C26.8008 5.4726 27.9052 6.57698 29.2675 6.57698Z"
        fill="white"
      />
      <path
        d="M36.996 10.5233C38.2675 10.5233 39.2982 9.49262 39.2982 8.22115C39.2982 6.94968 38.2675 5.91895 36.996 5.91895C35.7246 5.91895 34.6938 6.94968 34.6938 8.22115C34.6938 9.49262 35.7246 10.5233 36.996 10.5233Z"
        fill="white"
      />
      <path
        d="M43.5734 15.4561C44.6632 15.4561 45.5466 14.5727 45.5466 13.4829C45.5466 12.3932 44.6632 11.5098 43.5734 11.5098C42.4836 11.5098 41.6002 12.3932 41.6002 13.4829C41.6002 14.5727 42.4836 15.4561 43.5734 15.4561Z"
        fill="white"
      />
      <path
        d="M49.4933 21.211C50.3104 21.211 50.9729 20.5485 50.9729 19.7314C50.9729 18.9142 50.3104 18.2517 49.4933 18.2517C48.6761 18.2517 48.0136 18.9142 48.0136 19.7314C48.0136 20.5485 48.6761 21.211 49.4933 21.211Z"
        fill="white"
      />
    </svg>
  );
};

export default SeLogoWhite;
