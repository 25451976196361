import React from "react";

const PoliceBadge = ({ color = "#1868DB" }) => {
  return (
    <svg
      width="27"
      height="35"
      viewBox="0 0 31 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.25 26.25L15.5 22.3125L20.6562 26.25L18.6875 19.875L23.9375 15.75H17.5625L15.5 9.375L13.4375 15.75H7.0625L12.2188 19.875L10.25 26.25ZM15.5 38.25C11.1562 37.1562 7.57031 34.6641 4.74219 30.7734C1.91406 26.8828 0.5 22.5625 0.5 17.8125V6.375L15.5 0.75L30.5 6.375V17.8125C30.5 22.5625 29.0859 26.8828 26.2578 30.7734C23.4297 34.6641 19.8438 37.1562 15.5 38.25ZM15.5 34.3125C18.75 33.2812 21.4375 31.2188 23.5625 28.125C25.6875 25.0312 26.75 21.5938 26.75 17.8125V8.95312L15.5 4.73438L4.25 8.95312V17.8125C4.25 21.5938 5.3125 25.0312 7.4375 28.125C9.5625 31.2188 12.25 33.2812 15.5 34.3125Z"
        fill={color}
      />
    </svg>
  );
};

export default PoliceBadge;
