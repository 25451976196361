import React, { useEffect, useState } from "react";
import classes from "./HomePage.module.scss";
import Button from "../../../Components/Button/Button";
import { ReactComponent as PlayCirlce } from "../../../assets/icons/play_circle.svg";
import { ReactComponent as LinkedInIcon } from "../../../assets/icons/linkedin.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/circle_close.svg";
import useIsMobile from "../../../Hooks/UseIsMobile";
import BackgroundImageWithoutWhite from "../../../assets/images/background_without_white.png";

const HomePage = () => {
  const [videoVisible, setVideoVisible] = useState(false);
  const isMobile = useIsMobile(900);

  const watchOverviewClickHandler = () => {
    setVideoVisible(true);
  };

  const blackScreenClickHandler = () => {
    setVideoVisible(false);
  };

  useEffect(() => {
    // Set the tab name
    document.title = "CLEM | Profitable Clean Energy Mechanism Solutions";

    // Set the meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "Discover CLEM, a data-driven approach to profitable clean energy transitions. Optimize your coal asset transitions with expert insights and advanced technology."
      );
    } else {
      // If the meta tag doesn't exist, create it
      const meta = document.createElement("meta");
      meta.name = "description";
      meta.content =
        "Discover CLEM, a data-driven approach to profitable clean energy transitions. Optimize your coal asset transitions with expert insights and advanced technology.";
      document.head.appendChild(meta);
    }

    // Clean up (if necessary) when component unmounts
    return () => {
      document.title = "Default Title"; // Restore the default title
    };
  }, []);

  return (
    <div className={classes["homepage-container"]}>
      <div className={classes["home-page-image-text-container"]}>
        <div className={classes["home-page-image-container"]}>
          <img
            src={BackgroundImageWithoutWhite}
            alt="logo"
            width={"100%"}
            height={"100%"}
          />
        </div>
        <div className={classes["white-image-layer"]}></div>
        <div className={classes["home-page-content"]}>
          <p className={classes["home-page-content-heading"]}>
            <div className={classes["making-text"]}>Making</div>
            <div className={classes["nt-text"]}>Net-Zero Transitions</div>
            <div className={classes["profitable-text"]}>Profitable</div>
          </p>
          <p className={classes["home-page-content-desc"]}>
            Embark on a transformative journey with our expert guidance as you
            assess your current energy landscape, strategically plan your
            transition from fossil fuels to clean energy
          </p>
          <Button
            text={
              <div className={classes["watch-overview-btn"]}>
                Watch Overview <PlayCirlce />
              </div>
            }
            btnClickHandler={watchOverviewClickHandler}
            btnStyles={{ backgroundColor: "black" }}
          />
        </div>
      </div>
      {/* {!isMobile && (
        <div className={classes["comment-btn"]}>
          <LinkedInIcon />
        </div>
      )} */}

      {videoVisible && (
        <div
          className={classes["black-screen"]}
          onClick={blackScreenClickHandler}
        >
          <div className={classes["video-container"]}>
            <iframe
              height={"100%"}
              width={"100%"}
              src="https://sustainabilityeconomicsnews.com/wp-content/uploads/2024/08/CLEM-final-3.mp4"
            ></iframe>
            <div className={classes["close-icon-container"]}>
              <CloseIcon />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomePage;
